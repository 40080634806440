import React, { useEffect } from "react";
import { View, TouchableOpacity, Image } from "react-native";
import { Text } from "react-native-elements";
import { useSelector, useDispatch } from "react-redux";

import tw from "tailwind-rn";
import get from "lodash/get";
import isArray from "lodash/isArray";

import { PrintIframeAction, Tooltip } from "components/elements";
import { FormField } from "components/elements/forms";

import { fonts, colors, shapes, fontWeights } from "styles/theme";
import IMAGES from "static/assets/images";

import I18NContext from "library/contexts/i18N";

import useStateIfMounted from "library/utils/useStateIfMounted";
import { trimSpecialChars, formatPrice } from "library/utils/formatter";
import { request } from "library/utils/request";

import ChargesComponent from "components/views/drawer/order-details/payment-details/order-details/charges-summary";
import {
  selectIsEditOrder,
  selectOrderItemTabs,
  selectActiveItemTab,
} from "library/sagas/views/home/drawer/create-order/selector";
import { setActiveOrderItemTab } from "library/sagas/views/home/drawer/create-order/slice";

import { selectSelectedProducts } from "library/sagas/ongoing/global-data/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";

import {
  getRecipeInfo,
  getOccasionLabel,
} from "../../order-details/product-details/helper";
import { isMHQNonCoreMember } from "library/utils/entitlements";

const AdditionalInfoArea = ({
  index,
  formIKPath,
  formValues,
  isAutoFill = false,
  enableCPS = false,
  sendingMemberCode,
  canModifyOrder = true,
  isSmallScreen,
  setFieldValue,
}) => {
  const dispatch = useDispatch();

  const { messages, Localise } = React.useContext(I18NContext);
  const [occasionsList, setOccasionsList] = useStateIfMounted([]);

  const customerInfoPath = "customerInfo";

  const orderItem = get(formValues, `${formIKPath}`, {});

  const { deliveryInfo = {}, lineItems = [] } = orderItem;
  const { occasion = "" } = deliveryInfo;

  const productsCount = (isArray(lineItems) && lineItems.length) || 0;
  const orderHasProducts = productsCount > 0;

  const selectedProducts = useSelector(selectSelectedProducts);
  const activeItemTab = useSelector(selectActiveItemTab);
  const isMHQNonCoreShop = isMHQNonCoreMember(sendingMemberCode);
  const tabNames = useSelector(selectOrderItemTabs);

  const orderDetailsResponse = useSelector(selectApiResponse);
  const isEditOrder = useSelector(selectIsEditOrder);
  const isMultiOrderEligible =
    isMHQNonCoreShop && !isEditOrder && !formValues?.isQuickSaleEnabled;

  const orderDirection = get(
    orderDetailsResponse,
    `orderItems.${index}.direction`,
    ""
  );

  const originalSendingMember = get(
    orderDetailsResponse,
    `orderItems.${index}.sendingMember.memberCode`,
    ""
  );

  const selfOutGoingOrder =
    isEditOrder &&
    originalSendingMember === sendingMemberCode &&
    orderDirection === "OUTBOUND";

  const canShowNextArrow = tabNames.length > 1;
  const orderItemTitle = tabNames[activeItemTab].title;

  useEffect(() => {
    request("get-occasions", {}, undefined, true).then((res) => {
      setOccasionsList((res && res.occasions) || []);
    });
  }, []);

  return (
    <View>
      <View
        style={[
          isMultiOrderEligible && {
            ...shapes.sectionBorder,
            paddingHorizontal: 23,
            paddingVertical: 15,
          },
        ]}
      >
        {isMultiOrderEligible && (
          <View style={tw("flex-row items-center justify-between")}>
            <Text
              style={{
                ...fonts.sectionHeading,
                color: colors.highlighter,
              }}
            >
              {orderItemTitle}
            </Text>
            {canShowNextArrow && (
              <TouchableOpacity
                style={{ paddingHorizontal: 5 }}
                onPress={() => {
                  dispatch(
                    setActiveOrderItemTab(
                      tabNames.length !== activeItemTab + 1 ? index + 1 : 0
                    )
                  );
                }}
                testID="forward-order-item-arrow"
                accessibilityLabel="forward-order-item-arrow"
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  resizeMode="contain"
                  source={IMAGES[`back_right_nav_arrow`]}
                />
              </TouchableOpacity>
            )}
          </View>
        )}

        {enableCPS && !formValues?.isQuickSaleEnabled && (
          <View
            style={[
              {
                marginTop: 15,
                opacity: canModifyOrder || selfOutGoingOrder ? 1 : 0.7,
              },
              !isMultiOrderEligible && {
                ...shapes.sectionBorder,
                paddingHorizontal: 23,
                paddingVertical: 15,
              },
            ]}
            pointerEvents={
              canModifyOrder || selfOutGoingOrder ? "auto" : "none"
            }
          >
            <View style={[tw("flex flex-col")]}>
              <Text
                style={{
                  ...fonts.sectionHeading,
                  ...tw("mr-3"),
                  color: colors.highlighter,
                  marginBottom: 10,
                }}
              >
                {Localise(messages, "Customer Notes")}
              </Text>

              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="new-password"
                name="customerNotes"
                path={customerInfoPath}
                containerStyle={{
                  width: "100%",
                  paddingLeft: 0,
                }}
                placeholder={Localise(messages, "Customer Notes")}
                multiline={true}
                numberOfLines={5}
                maxNumberOfLines={5}
                spellCheck={true}
              />
            </View>
          </View>
        )}

        {orderHasProducts && (
          <View>
            {lineItems.map((product, idx) => (
              <ProductDetails
                key={idx}
                index={idx}
                product={product}
                Localise={Localise}
                messages={messages}
                selectedProducts={selectedProducts}
                occasion={occasion}
                occasionsList={occasionsList}
              />
            ))}
          </View>
        )}
      </View>
      {isEditOrder && <ChargesComponent />}
    </View>
  );
};

const ProductDetails = ({
  index,
  product,
  Localise,
  messages,
  selectedProducts,
  occasion,
  occasionsList,
}) => {
  const [showSubstitution, setShowSubstitution] = useStateIfMounted(false);

  const {
    productFirstChoiceDescription = "",
    productFirstChoiceRefNumberId = "",
    productFirstChoiceCode,
    productSecondChoiceCode,
    productSecondChoiceDescription,
    img,
    type,
  } = product || {};

  const isProduct = type === "Product";

  const currentProductInfo = selectedProducts.find(
    (product) => product.productId === productFirstChoiceCode
  );

  const {
    dimension = "",
    actualPrice,
    recipeList = [],
    isFTDOrder = false,
  } = currentProductInfo || {};

  return (
    <View
      key={index}
      style={{
        ...shapes.sectionBorder,
        marginRight: 10,
        marginTop: 15,
        width: "100%",
      }}
    >
      <View style={tw("flex")}>
        <View style={tw("flex flex-row justify-between")}>
          <Text style={[tw("mb-4"), fonts.sectionHeading]}>
            {Localise(messages, `${isProduct ? "Product" : "Add-on"} Details`)}
          </Text>

          {isProduct && recipeList?.length ? (
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => {
                PrintIframeAction(
                  getRecipeInfo(
                    {
                      productFirstChoiceCode,
                      occasion: getOccasionLabel(occasionsList, occasion),
                      formattedPrice: formatPrice(actualPrice),
                      productFirstChoiceDescription,
                      img,
                      actualPrice,
                      ...currentProductInfo,
                    },
                    Localise,
                    messages,
                    isFTDOrder
                  )
                );
              }}
              testID="print_recipe"
              accessibilityLabel="print_recipe"
            >
              <Tooltip
                text={Localise(messages, "Print Recipe")}
                renderForWebOnly={true}
              >
                <Image
                  style={{
                    width: 26,
                    height: 26,
                  }}
                  source={IMAGES["print"]}
                />
              </Tooltip>
            </TouchableOpacity>
          ) : null}
        </View>

        <Text
          style={[
            tw("mb-1"),
            fonts.default,
            {
              color: colors.primary,
              fontWeight: fontWeights.bold,
            },
          ]}
          numberOfLines={2}
        >
          {productFirstChoiceRefNumberId + " " + productFirstChoiceDescription}
        </Text>

        <Text
          style={[
            tw("mb-1"),
            fonts.default,
            {
              color: colors.primary,
              fontWeight: "normal",
            },
          ]}
        >
          {Localise(messages, "Product Code")}
          {": "}
          {trimSpecialChars(productFirstChoiceCode, ".")}
        </Text>

        {dimension.length > 0 && (
          <Text
            style={[
              tw("mb-1"),
              fonts.default,
              {
                color: colors.primary,
                fontWeight: "normal",
              },
            ]}
          >
            {Localise(messages, "Size / Style")}: {dimension}
          </Text>
        )}

        {productSecondChoiceCode && productSecondChoiceCode !== "NONE" && (
          <View style={[tw("my-2")]}>
            <View style={tw("flex flex-row")}>
              <TouchableOpacity
                onPress={() => setShowSubstitution(!showSubstitution)}
                testID="substitution"
                accessibilityLabel="substitution"
              >
                <Text style={[fonts.link]}>
                  {Localise(messages, "Substitution")}
                </Text>
              </TouchableOpacity>
            </View>

            {showSubstitution && (
              <View style={tw("flex flex-wrap items-baseline ml-2 my-1")}>
                <View style={tw("mb-1")}>
                  <Text>
                    {productSecondChoiceDescription.split("\\n").join("\n")}
                  </Text>
                </View>
                <View style={tw("my-1")}>
                  <Text>
                    {Localise(messages, "Product Code:")}{" "}
                    {trimSpecialChars(productSecondChoiceCode, ".")}
                  </Text>
                </View>
              </View>
            )}
          </View>
        )}

        {isProduct && recipeList?.length > 0 && (
          <View style={[tw("mt-3")]}>
            <Text>{Localise(messages, "Recipe")}</Text>
            <View
              style={[
                tw("flex flex-col mt-1"),
                {
                  borderColor: colors.grayScaleLight,
                  borderWidth: 1,
                  borderRadius: 2,
                  paddingTop: 11,
                  paddingRight: 11,
                  paddingBottom: 8,
                  paddingLeft: 14,
                },
              ]}
            >
              {recipeList.map((ele, index) => (
                <View key={index} style={tw("flex flex-row p-1")}>
                  <Text style={tw(`w-1/4 ${ele.css || ""}`)}>
                    {Localise(messages, ele.quantity)}
                  </Text>
                  {!isFTDOrder && (
                    <Text
                      style={{
                        ...tw(`w-1/4 ${ele.css || ""}`),
                        paddingLeft: 2,
                      }}
                    >
                      {Localise(messages, ele.color) || "-"}
                    </Text>
                  )}
                  <Text style={tw(`w-2/4 ${ele.css || ""}`)}>
                    {Localise(messages, ele.item)}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default AdditionalInfoArea;
