import React, { useEffect, useContext, useMemo } from "react";
import { Platform, View, Text } from "react-native";
import { connect, useSelector } from "react-redux";
import { useFormikContext } from "formik";

import {
  FormField,
  FormFieldDatePicker,
  FormFieldPicker,
  FormFieldSwitch,
} from "components/elements/forms";
import { Distance } from "components/elements/distance";

import tw from "tailwind-rn";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import set from "lodash/set";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { fonts, shapes, backgroundColors } from "styles/theme";

import I18NContext from "library/contexts/i18N";

import Environment from "library/utils/environment";

import {
  isWireOrder,
  isPhoneOutOrder,
} from "../../order-details/delivery-info/helper";
import UserProfileStorage from "library/storage/userProfile";
import {
  selectIsEditOrder,
  selectRushDeliveryFee,
} from "library/sagas/views/home/drawer/create-order/selector";
import { selectAddressVerificationInfo } from "library/sagas/ongoing/global-data/selector";
import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { isEligibleForRush } from "library/utils/createOrder";
import {
  digitalGiftCardProducts,
  physicalGiftCardProducts,
} from "library/utils/giftCardProducts";
import { ToasterHandler, Tooltip, DeliveryZone } from "components/elements";
import AppSettingsContext from "library/contexts/appSettings";
import {
  isTimedDeliveryAvailable as hasTimedDelivery,
  hasDeliveryZone,
} from "library/utils/featureAvailability";

// rounding time to nearest 15min to show it in pickup time drop down
const roundUpTime = (intervalMilliseconds, datetime) => {
  datetime = datetime || new Date();
  var modTicks = datetime.getTime() % intervalMilliseconds;
  var delta = modTicks === 0 ? 0 : datetime.getTime() - modTicks;
  delta += intervalMilliseconds;
  return new Date(delta);
};

export const getDeliveryTimeMinAndMaxTime = ({
  isStorePickupOrder = false,
  deliveryDate,
}) => {
  const minTime = isStorePickupOrder
    ? moment(deliveryDate).isSame(moment(), "day")
      ? new Date()
      : new Date(deliveryDate).setHours(0, 0)
    : deliveryDate && moment(deliveryDate).isSame(moment(), "day")
    ? moment(roundUpTime(30 * 60 * 1000, new Date())).toDate()
    : deliveryDate && !moment(deliveryDate).isSame(moment(), "day")
    ? moment().startOf("day").toDate()
    : new Date();
  const maxTime = isStorePickupOrder
    ? new Date(deliveryDate).setHours(23, 59)
    : deliveryDate
    ? moment().endOf("day").toDate()
    : new Date();

  return { minTime, maxTime };
};

//eslint-disable-next-line
export const DeliveryInfo = React.memo(
  ({
    isSmallScreen,
    index,
    title,
    setValues,
    isAutoFill,
    eligibleDeliveryMethods,
    sendingMemberCode,
    canModifyOrder = true,
    isMultiOrderEligible,
    actualDeliveryMethod = "",
  }) => {
    const { values, setFieldValue, initialValues } = useFormikContext();
    const { messages, Localise } = useContext(I18NContext);
    const isEditOrder = useSelector(selectIsEditOrder);
    const rushDeliveryFee = useSelector(selectRushDeliveryFee);
    const addressVerificationInfo = useSelector(selectAddressVerificationInfo);
    const orderDetailsResponse = useSelector(selectApiResponse);

    const shopLocations = UserProfileStorage.getAllShopLocation();
    const selectedShopPreferences =
      UserProfileStorage.getShopPreferences(sendingMemberCode);
    const shopLocation = shopLocations[sendingMemberCode] || {};
    const { permissions = {} } = useContext(AppSettingsContext);
    const isTimedDeliveryAvailable = hasTimedDelivery(
      permissions,
      sendingMemberCode
    );

    const orderItem = get(values, `orderItems.${index}`, {});
    const {
      deliveryInfo: {
        deliveryMethod = "",
        deliveryDate,
        pickUpDateTime = "",
        orderDeliveryTime = "",
      },
      recipientInfo: { state = "", addressLine1 },
      lineItems: [lineItemsInfo = {}],
      isRushOrder = false,
    } = orderItem;

    const {
      isAvsPerformed = "",
      isAvsSuggestedAddress = "",
      avsConfidence = "",
    } = addressVerificationInfo[index] || {};
    const isAvsVerified =
      isAvsPerformed === "Y" &&
      isAvsSuggestedAddress === "Y" &&
      avsConfidence === "HIGH";

    const { productFirstChoiceCode = "" } = lineItemsInfo;

    const isDigitalGiftCard = digitalGiftCardProducts.includes(
      productFirstChoiceCode
    );
    const isPhysicalGiftCard = physicalGiftCardProducts.includes(
      productFirstChoiceCode
    );
    const isEmailOrder = orderItem.orderSource === "Email Order";

    const digitalGcDeliveryMethod = [{ label: "Digital", value: "WALK_IN" }];
    const physicalGcDeliveryMethod = [{ label: "Walk-In", value: "WALK_IN" }];

    const deliveryMethods =
      isEditOrder && canModifyOrder
        ? eligibleDeliveryMethods.filter(
            (each) => each.value !== "FLORIST_PARTNER"
          )
        : eligibleDeliveryMethods;

    if (isEditOrder && !canModifyOrder) {
      deliveryMethod === "MOL_FLORIST_DELIVERED" &&
        deliveryMethods.push({
          label: "MOL Florist Delivered",
          value: "MOL_FLORIST_DELIVERED",
        });
    }

    const path = `orderItems.${index}.deliveryInfo`;
    const parentPath = `orderItems.${index}`;
    const { direction } = get(orderDetailsResponse, parentPath, {});

    const isStorePickupOrder = deliveryMethod === "STORE_PICKUP";
    const isWalkInOrder = deliveryMethod === "WALK_IN";
    const isFloristDelivered = deliveryMethod === "FLORIST_DELIVERED";

    const shopOpenHours = UserProfileStorage.getShopHours(sendingMemberCode);

    const rushFeeEnabled =
      selectedShopPreferences?.rush_fee_enabled?.toLowerCase() ?? "false";

    const rushSubmitBy = get(selectedShopPreferences, "rush_submitted_by", "");

    const rushDeliverBy = get(selectedShopPreferences, "rush_delivered_by", "");

    const defaultIsRushOrder = get(
      initialValues,
      `${parentPath}.isRushOrder`,
      false
    );

    const defaultDeliveryFee = parseFloat(
      get(initialValues, `${parentPath}.defaultDeliveryFee`, 0)
    );

    const deliveryFeeInfo = get(values, `${parentPath}.deliveryFeeInfo`, {});
    const { localFee = 0, outsideLocalFee = 0 } = deliveryFeeInfo;

    const localDeliveryFee = isEditOrder
      ? defaultIsRushOrder
        ? defaultDeliveryFee - parseFloat(rushDeliveryFee)
        : defaultDeliveryFee
      : parseFloat(localFee);

    const defaultDeliveryInstructions = get(
      initialValues,
      `${parentPath}.deliveryInfo.deliveryInstructions`,
      ""
    );

    const hasMaxLimit =
      !defaultDeliveryInstructions || defaultDeliveryInstructions.length <= 100;

    const other = hasMaxLimit
      ? { maxLength: 100, showRemainingChars: true }
      : {};

    const defaultDeliveryDate = get(
      initialValues,
      `${parentPath}.deliveryInfo.deliveryDate`,
      false
    );

    const showRushOrder =
      isFloristDelivered &&
      (isEligibleForRush(deliveryDate, rushSubmitBy) ||
        (isEditOrder &&
          defaultIsRushOrder &&
          deliveryDate === defaultDeliveryDate));

    const showDeliveryZone = useMemo(
      () =>
        hasDeliveryZone({
          shopCode: sendingMemberCode,
          deliveryMethod,
          direction,
          addressLine1,
        }),
      [sendingMemberCode, deliveryMethod, direction, addressLine1]
    );

    const formatAndSetDateTime = (fieldName, fieldValue) => {
      const time = moment(fieldValue, "YYYY-MM-DDTHH:mm:ss").format("HH:mm:ss");
      const formattedDateTime = `${moment(deliveryDate).format(
        "YYYY-MM-DD"
      )}T${time}`;

      const prevOrderDeliveryTime = get(
        initialValues,
        `${path}.orderDeliveryTime`,
        ""
      );

      if (moment().isBefore(formattedDateTime)) {
        setFieldValue(`${path}.${fieldName}`, formattedDateTime);
      } else {
        // Retaining the orderDeliveryTime while modifying an order with a past delivery date.
        if (isEditOrder && prevOrderDeliveryTime === formattedDateTime) return;
        setFieldValue(`${path}.${fieldName}`, "");
      }
    };

    const getShopHoursForSelectedDate = (date) => {
      let dayOfWeek = moment(date).format("dddd").toUpperCase();

      let shopHours = shopOpenHours.filter(
        (dayHours) => dayHours.day.toUpperCase() === dayOfWeek
      );

      return shopHours?.length ?? 0 > 0
        ? shopHours[0]
        : {
            openTime: "00:00:00",
            closeTime: "24:00:00",
          };
    };

    const updateShopDayTimings = (date, isInitialLoading = true) => {
      if (!isStorePickupOrder) return;
      let shopHours = getShopHoursForSelectedDate(date);

      let shopOpenTime = moment(date)
        .set({
          hour: parseInt(shopHours?.openTime?.split(":")[0]) || 0,
          minute: parseInt(shopHours?.openTime?.split(":")[1]) || 0,
          second: parseInt(shopHours?.openTime?.split(":")[2]) || 0,
        })
        .toDate();

      let shopCloseTime = moment(date)
        .set({
          hour: parseInt(shopHours?.closeTime?.split(":")[0]) || 0,
          minute: parseInt(shopHours?.closeTime?.split(":")[1]) || 0,
          second: parseInt(shopHours?.closeTime?.split(":")[2]) || 0,
        })
        .toDate();

      let shopTimings = {
        open: shopOpenTime,
        close: shopCloseTime,
      };

      let isToday = moment(date).isSame(moment(), "day");

      if (isToday) {
        if (moment().isAfter(shopOpenTime)) {
          if (moment().isBefore(shopCloseTime)) {
            shopTimings.open = moment(
              roundUpTime(15 * 60 * 1000, new Date())
            ).toDate();
          } else {
            shopTimings.open = moment().startOf("day").toDate();

            shopTimings.close = moment().startOf("day").toDate();
          }
        }
      }

      if (!isInitialLoading) {
        // Updating shop timings in Formik in order to access them while performing Yup validations
        const clonedValues = cloneDeep(values);
        set(clonedValues, `${path}.shopDayTimings`, shopTimings);

        if (pickUpDateTime) {
          let pickUpTimeOnly = moment(
            pickUpDateTime,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("HH:mm:ss");

          let formattedPickupTime = `${moment(date).format(
            "YYYY-MM-DD"
          )}T${pickUpTimeOnly}`;

          set(clonedValues, `${path}.pickUpDateTime`, formattedPickupTime);
        }

        setTimeout(() => {
          setValues(clonedValues);
        }, 1000);
      }

      return shopTimings;
    };

    useEffect(() => {
      // Need to revisit this setTimeOut in future. Keeping it because of orderType & deliveryMethods not updating properly.
      const isDeliveryMethodNotChanged =
        actualDeliveryMethod === deliveryMethod;
      setTimeout(() => {
        setFieldValue(`${parentPath}.deliveryMethod`, deliveryMethod);
        setFieldValue(
          `${parentPath}.recipientInfo.deliveryMethod`,
          deliveryMethod
        );

        setFieldValue(
          `${parentPath}.relayFee`,
          Environment.get("SHOW_RELAY_FEE", false)
            ? isWireOrder(deliveryMethod)
              ? get(selectedShopPreferences, "relay_fee", "0.00")
              : isPhoneOutOrder(deliveryMethod)
              ? isEditOrder && isDeliveryMethodNotChanged
                ? get(orderItem, "oldValues.relayFee", "0.00")
                : get(selectedShopPreferences, "relay_fee", "0.00")
              : isEmailOrder && isEditOrder
              ? get(orderItem, "oldValues.relayFee", "0.00")
              : 0
            : 0
        );
      }, 500);

      if (isStorePickupOrder || isWalkInOrder) {
        setFieldValue(`${parentPath}.deliveryFee`, 0);
      } else {
        if (deliveryMethod !== "") {
          if (!isEmpty(deliveryFeeInfo)) {
            setFieldValue(
              `${parentPath}.deliveryFee`,
              isFloristDelivered
                ? parseFloat(localFee).toFixed(2)
                : parseFloat(outsideLocalFee).toFixed(2)
            );
          } else if (isEditOrder) {
            const oldDeliveryFee = get(
              orderItem,
              "oldValues.totalDeliveryFee",
              "0.00"
            );
            setFieldValue(`${parentPath}.deliveryFee`, oldDeliveryFee);
          }
        } else {
          setFieldValue(`${parentPath}.deliveryFee`, "0.00");
        }
      }
      setFieldValue(
        `${parentPath}.retailDeliveryFee`,
        !(isStorePickupOrder || isWalkInOrder) && state === shopLocation?.state
          ? get(selectedShopPreferences, "retail_delivery_fee", "0.00")
          : "0.00"
      );

      //Reset isRushOrder to false on Delivery Method change
      if (!isFloristDelivered && isRushOrder)
        setFieldValue(`${parentPath}.isRushOrder`, false);
    }, [deliveryMethod]);

    useEffect(() => {
      if (deliveryDate) {
        updateShopDayTimings(deliveryDate, false);

        //Don't Reset isRushOrder on Edit order page load.
        if (isEditOrder) {
          if (
            defaultDeliveryDate === deliveryDate &&
            defaultIsRushOrder === isRushOrder
          )
            return;
        }

        if (orderDeliveryTime && deliveryDate) {
          formatAndSetDateTime("orderDeliveryTime", orderDeliveryTime);
        }

        //Reset isRushOrder to false on date change and crossed the submit time
        if (
          isFloristDelivered &&
          isRushOrder &&
          !isEligibleForRush(deliveryDate, rushSubmitBy)
        )
          setFieldValue(`${parentPath}.isRushOrder`, false);
      }
    }, [deliveryDate]);

    useEffect(() => {
      if (pickUpDateTime && deliveryDate) {
        // Updating Pickup Time with selected delivery date in Formik in order to access it while performing Yup validations
        const pickUpTimeOnly = moment(
          pickUpDateTime,
          "YYYY-MM-DDTHH:mm:ss"
        ).format("HH:mm:ss");

        const formattedPickupTime = `${moment(deliveryDate).format(
          "YYYY-MM-DD"
        )}T${pickUpTimeOnly}`;

        setFieldValue(`${path}.pickUpDateTime`, formattedPickupTime);
      }
    }, [pickUpDateTime]);

    useEffect(() => {
      if (deliveryDate) {
        if (orderDeliveryTime) {
          formatAndSetDateTime("orderDeliveryTime", orderDeliveryTime);
        } else if (isRushOrder) {
          formatAndSetDateTime("orderDeliveryTime", rushDeliverBy);
        }
      }
    }, [orderDeliveryTime]);

    useEffect(() => {
      if (isRushOrder && deliveryDate) {
        if (!orderDeliveryTime) {
          formatAndSetDateTime("orderDeliveryTime", rushDeliverBy);
        }
      }
      if (isFloristDelivered) {
        const deliveryFee = isRushOrder
          ? localDeliveryFee + parseFloat(rushDeliveryFee)
          : localDeliveryFee;

        setFieldValue(
          `${parentPath}.deliveryFee`,
          parseFloat(deliveryFee).toFixed(2)
        );
      }
    }, [isRushOrder]);

    const { minTime, maxTime } = getDeliveryTimeMinAndMaxTime({
      isStorePickupOrder,
      deliveryDate,
    });

    const isTimePickerDisabled =
      isEditOrder && moment(deliveryDate).isBefore(moment(), "day");

    return (
      <View
        style={[
          {
            marginTop: 15,
          },
          !isMultiOrderEligible && { ...shapes.sectionBorder },
        ]}
      >
        <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
          <Text style={[fonts.sectionHeading]}>
            {Localise(messages, title)}
          </Text>
        </View>

        <View
          style={[
            tw("flex flex-row flex-wrap"),
            { zIndex: isAutoFill ? 0 : 1, opacity: canModifyOrder ? 1 : 0.7 },
          ]}
          pointerEvents={canModifyOrder ? "auto" : "none"}
        >
          <FormFieldPicker
            containerStyle={{
              width: isSmallScreen ? "100%" : isWalkInOrder ? "40%" : "30%",
            }}
            placeholder={{
              label: "Select Delivery Method",
              value: "",
            }}
            data={
              isDigitalGiftCard
                ? digitalGcDeliveryMethod
                : isPhysicalGiftCard
                ? physicalGcDeliveryMethod
                : deliveryMethods
            }
            name="deliveryMethod"
            label={"Delivery Method"}
            labelStyle={{ fontWeight: "normal" }}
            path={path}
            parentPath={parentPath}
            disabledFieldTouch={true}
          />

          {!isWalkInOrder && (
            <>
              <FormFieldDatePicker
                dateValueFormat="YYYY-MM-DD"
                name="deliveryDate"
                label={isStorePickupOrder ? "Pickup Date" : "Delivery Date"}
                labelStyle={{ fontWeight: "normal" }}
                placeholder="MM / DD / YYYY"
                containerStyle={{
                  width: isSmallScreen ? "100%" : "25%",
                  zIndex: isAutoFill ? 0 : 15,
                }}
                path={path}
              />
              {(isStorePickupOrder || isTimedDeliveryAvailable) && (
                <FormFieldDatePicker
                  name={
                    isStorePickupOrder ? "pickUpDateTime" : "orderDeliveryTime"
                  }
                  label={isStorePickupOrder ? "Pickup Time" : "Delivery Time"}
                  labelStyle={{ fontWeight: "normal" }}
                  placeholder="Time"
                  containerStyle={{
                    width: isSmallScreen ? "100%" : "20%",
                    zIndex: isAutoFill ? 0 : 10,
                  }}
                  path={path}
                  timeIntervals={isStorePickupOrder ? 15 : 30}
                  iconName="clock"
                  alignIconRight={true}
                  showTimeSelect={true}
                  showTimeSelectOnly={true}
                  minTime={minTime}
                  maxTime={maxTime}
                  dateFormat="h:mm a"
                  allowClear={true}
                  {...(Platform.OS !== "web" && {
                    dateValueFormat: "YYYY-MM-DDTHH:mm:ss",
                  })}
                  // Disabling the time picker while modifying an order with a past delivery date.
                  {...(!isStorePickupOrder && {
                    disabled: isTimePickerDisabled,
                    editable: !isTimePickerDisabled,
                    ...(isTimePickerDisabled && {
                      inputContainerStyle: {
                        backgroundColor: backgroundColors.greyColor,
                      },
                    }),
                  })}
                />
              )}

              {showRushOrder && (
                <View
                  style={[
                    tw("flex flex-row flex-wrap px-1 mb-3"),
                    !isSmallScreen && { marginTop: 24, width: "25%" },
                  ]}
                >
                  <View style={tw("flex justify-center")}>
                    <FormFieldSwitch
                      disabled={!(rushFeeEnabled === "true")}
                      value={!!isRushOrder}
                      onValueChange={(val) => {
                        setFieldValue(`${parentPath}.isRushOrder`, val);
                        val &&
                          ToasterHandler(
                            "hooray",
                            Localise(
                              messages,
                              "A rush order fee has been applied to this order."
                            )
                          );
                      }}
                      testID="Rush_Order"
                    />
                  </View>
                  <View style={tw("flex justify-center")}>
                    <Tooltip
                      text={`Adds $${rushDeliveryFee} delivery fee upcharge to rushed orders.`}
                      isSmallScreen={isSmallScreen}
                      height={80}
                    >
                      <Text
                        style={[
                          fonts.default,
                          tw("flex flex-wrap items-center justify-center px-1"),
                        ]}
                      >
                        {Localise(messages, "Rush Order")}
                      </Text>
                    </Tooltip>
                  </View>
                </View>
              )}

              {isStorePickupOrder && (
                <FormField
                  autoCapitalize="none"
                  autoCorrect={false}
                  name="pickUpBy"
                  label={"Pickup By"}
                  labelStyle={{ fontWeight: "normal" }}
                  placeholder={"Enter Pickup By"}
                  containerStyle={{
                    width: isSmallScreen ? "100%" : "25%",
                  }}
                  path={path}
                  editable={isAutoFill ? false : true}
                />
              )}
            </>
          )}
        </View>

        <View style={[tw("flex flex-row flex-wrap items-center my-1")]}>
          <Distance
            memberCode={sendingMemberCode}
            recipientInfo={orderItem?.recipientInfo}
            isAvsVerified={isAvsVerified}
            customStyles={{ fontSize: 11 }}
            customContainerStyles={{
              marginTop: 0,
              marginLeft: 5,
              marginBottom: 8,
            }}
            calculateDistance={isFloristDelivered}
            deliveryMethod={deliveryMethod}
          />

          {/* Delivery Zone Name */}
          {showDeliveryZone ? (
            <DeliveryZone {...{ memberCode: sendingMemberCode, index }} />
          ) : null}
        </View>

        <FormField
          autoCapitalize="none"
          autoCorrect={false}
          name="deliveryInstructions"
          label={Localise(
            messages,
            isStorePickupOrder
              ? "Pickup Notes"
              : isWalkInOrder
              ? "Notes"
              : "Delivery Instructions"
          )}
          labelStyle={{ fontWeight: "normal" }}
          placeholder={
            isStorePickupOrder
              ? "Pickup Notes"
              : isWalkInOrder
              ? "Notes"
              : "Add Instructions"
          }
          containerStyle={{
            width: "100%",
            paddingHorizontal: 5,
          }}
          outerContainerStyle={{}}
          path={path}
          editable={isAutoFill ? false : true}
          multiline={true}
          numberOfLines={3}
          maxNumberOfLines={5}
          {...other}
        />
      </View>
    );
  }
);

const mapStateToProps = (state = {}) => {
  return {
    isAutoFill: state.mhq.ongoing.global.isAutoFill,
  };
};

export default connect(mapStateToProps, null)(DeliveryInfo);
