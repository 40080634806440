import React, { useEffect, useState, useRef } from "react";
import { View, Platform, Image, ActivityIndicator } from "react-native";
import { Text } from "react-native-elements";
import { Form } from "components/elements/forms";
import { request } from "library/utils/request";
import { useSelector, useDispatch } from "react-redux";
import {
  FormFieldPicker,
  FormFieldAutoComplete,
} from "components/elements/forms";
import IMAGES from "static/assets/images";

import I18NContext from "library/contexts/i18N";
import UserProfileContext from "library/contexts/userProfile";
import tw from "tailwind-rn";
import moment from "moment";
import { Accordion, ToasterHandler } from "components/elements";
import { FuneralLogFields, FuneralLogAdditionalFields } from "../ui-config";
import { DeviceContext } from "library/contexts/appSettings";
import Address from "components/elements/address";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { createFuneralLogValidationSchema } from "../../../create-event/config";
import { SaveCancelButtons } from "components/wrappers";
import {
  selectCreateOrderShopCode,
  selectActiveItemTab,
} from "library/sagas/views/home/drawer/create-order/selector";
import {
  setPageAction,
  fetchAllEvents,
  setSelectedFuneralLog,
} from "library/sagas/views/home/drawer/events/slice";
import { fonts, backgroundColors, colors } from "styles/theme";
import { useFormikContext } from "formik";
import { locationList } from "components/views/drawer/create-order/config";
import {
  getOrderDetailsRequests,
  getCustomerDetailsRequests,
  getPrintHTML,
} from "./helper";
import { PrintIframeAction } from "components/elements";
import UserProfileStorage from "library/storage/userProfile";
import { useNavigation, CommonActions } from "@react-navigation/native";
import { ConfirmModal } from "components/wrappers";
import { getDeliveryTimeMinAndMaxTime } from "components/views/drawer/create-order/delivery-info";
import { selectFuneralLogActualEvents } from "library/sagas/views/home/drawer/events/selector";
import uniq from "lodash/uniq";

// Created common Location Info, which can be useful for other screens in future.
const LocationInfo = ({
  locationType,
  address,
  isSmallScreen,
  path,
  onSelect,
  zIndex,
}) => {
  const { country, city, state, zip } = address;
  const [locationSuggestions, setLocationSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAutoCompleteLocation = (text) => {
    if (text.trim().length < 3) {
      setLocationSuggestions([]);
      return;
    }
    if (locationType?.length > 0 && locationType !== "Residence") {
      setLoading(true);
      request("autocomplete-location", {
        input: text,
        country,
        city,
        state,
        zip,
        locationType: locationType === "Funeral" ? locationType : ``,
      })
        .then((res) => {
          if (res?.predictions?.length) {
            setLocationSuggestions(res.predictions);
          } else setLocationSuggestions([]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getLocationDetails = (selectedValue) => {
    if (!selectedValue || !selectedValue?.place_id) return;

    setLoading(true);
    request("google-geocode", {
      place_id: selectedValue.place_id,
    })
      .then((response) => {
        const getValue = (value) => {
          const address_components = response?.results[0]?.address_components;
          return address_components?.find((obj) => obj.types[0] === value);
        };
        const streetNum =
          getValue("street_number")?.short_name ||
          getValue("street_number")?.long_name ||
          ``;
        const route =
          getValue("route")?.short_name || getValue("route")?.long_name || ``;

        const streetAddress = streetNum + ` ` + route;
        const city =
          getValue("locality")?.short_name ||
          getValue("locality")?.long_name ||
          ``;
        const state = getValue("administrative_area_level_1")?.short_name || ``;
        const postalcode = getValue("postal_code")?.long_name || ``;
        const postalcode_suffix =
          getValue("postal_code_suffix")?.long_name || ``;
        const country = getValue("country")?.short_name || ``;
        const address = {
          addressLine1: streetAddress,
          city: city,
          zip:
            postalcode_suffix?.length > 0 && country !== "US"
              ? postalcode + `-` + postalcode_suffix
              : postalcode,
          state: state,
          country: country,
        };
        onSelect(selectedValue, address);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <View
      style={[
        tw(`flex flex-${isSmallScreen ? "column" : "row"}`),
        { width: "100%", zIndex },
      ]}
    >
      <FormFieldPicker
        containerStyle={{
          width: isSmallScreen ? "100%" : "33.5%",
        }}
        placeholder={{
          label: "Location Type",
        }}
        label={"Location Type"}
        labelStyle={{ fontWeight: "normal" }}
        data={locationList}
        name="locationType"
        path={path}
      />
      <FormFieldAutoComplete
        autoCapitalize="none"
        autoCorrect={false}
        loading={loading}
        name="locationName"
        fsClass="fs-exclude"
        placeholder={"Location or business name"}
        label={"Location Name"}
        labelStyle={{ fontWeight: "normal" }}
        path={path}
        data={locationSuggestions}
        suggestionsHeight={!isSmallScreen ? 120 : undefined}
        onChangeText={(text) => {
          getAutoCompleteLocation(text);
        }}
        outerContainerStyle={{
          width: isSmallScreen ? "100%" : "66.5%",
        }}
        listDisplayValues={["description"]}
        updateOnBlur={true}
        popperPlacement={"top"}
        onSelect={(selectedValue) => {
          getLocationDetails(selectedValue);
        }}
      />
    </View>
  );
};

const FuneralListItem = ({ index = 0, data = {}, defaultOpen, onComplete }) => {
  const TouchableComponent =
    Platform.OS === "web"
      ? require("react-native").TouchableOpacity
      : require("react-native-gesture-handler").TouchableOpacity;

  const navigation = useNavigation();
  const { isDesktop } = React.useContext(DeviceContext);
  const isSmallScreen = !isDesktop;
  const { messages, Localise } = React.useContext(I18NContext);
  const { memberCodes = [] } = React.useContext(UserProfileContext);
  const formRef = useRef(null);

  const { setFieldValue, values: createOrderValues } = useFormikContext();

  const [validationOnChange, setValidationOnChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const dispatch = useDispatch();
  const shopCode = useSelector(selectCreateOrderShopCode);
  const activeItemTab = useSelector(selectActiveItemTab);
  const funeralEvents = useSelector(selectFuneralLogActualEvents);

  const fields = FuneralLogFields(Localise, messages);
  const additionalFields = FuneralLogAdditionalFields(Localise, messages);
  const formIKPath = "locations.0";

  const deliveryDate = get(data, `${formIKPath}.deliveryDate`, "");
  const locationTimes = get(data, `${formIKPath}.locationTimes`, []);

  const visitationInfo = locationTimes.find((x) => x.label === "visitation");
  const serviceInfo = locationTimes.find((x) => x.label === "service");

  //Convert Event location propeties to match funeral log props
  const inputValues = !isEmpty(data)
    ? {
        ...data,
        locations: [
          {
            ...data?.locations[0],
            locationType: data?.locations[0].title,
            locationName: data?.locations[0].name,
            deliveryDate: moment(deliveryDate).format("YYYY-MM-DD"),
            deliveryTime: deliveryDate.includes("T")
              ? moment(deliveryDate).format("YYYY-MM-DDTHH:mm:ss")
              : "",
            visitationDate: visitationInfo?.startTime
              ? moment(visitationInfo?.startTime).format("YYYY-MM-DD")
              : "",
            visitationTime: visitationInfo?.startTime?.includes("T")
              ? moment(visitationInfo?.startTime).format("YYYY-MM-DDTHH:mm:ss")
              : "",
            serviceDate: serviceInfo?.startTime
              ? moment(serviceInfo?.startTime).format("YYYY-MM-DD")
              : "",
            serviceTime: serviceInfo?.startTime?.includes("T")
              ? moment(serviceInfo?.startTime).format("YYYY-MM-DDTHH:mm:ss")
              : "",
          },
        ],
      }
    : {
        celebrant: {
          firstName: "",
          lastName: "",
        },
        type: "FUNERAL_LOG",
        name: "",
        memberCode: shopCode && shopCode !== "all" ? shopCode : memberCodes[0],
        locations: [
          {
            deliveryDate: "",
            address: {
              addressLine1: "",
              city: "",
              state: "",
              zip: "",
              country: "US",
            },
            locationType: "Funeral",
            locationName: "",
          },
        ],
      };

  const {
    eventId,
    customer,
    celebrant: { firstName, lastName } = {},
    locations = [],
  } = data;
  let orderIds = []; //Array or orderIds - ["MHQ12345"];
  if (createOrderValues.sendingMember) {
    orderIds =
      customer?.details?.orderIds[createOrderValues.sendingMember] || [];
  } else {
    Object.entries(customer?.details?.orderIds || {}).forEach((entry) => {
      orderIds = orderIds?.concat(entry[1] || []);
    });
  }

  const orderIdsList = orderIds
    .filter((id) => !!id)
    .map((id) => id.split("$")[0]);

  let name = `${firstName} ${lastName}`;
  name = name.length > 30 ? `${name.slice(0, 30)}...` : name;
  const title = eventId
    ? `${name} - ${moment(locations[0].deliveryDate).format("MM/DD/YYYY")}`
    : `Recipient`;

  const ordersCount = orderIds?.length;

  //Update create-order form with funeral info
  const handleAddToOrder = (
    firstName,
    lastName,
    locationInfo = {},
    onComplete
  ) => {
    const {
      address = {},
      locationName = "",
      locationType = "",
      deliveryDate = "",
      deliveryTime = "",
      visitationDate = "",
      visitationTime = "",
      serviceDate = "",
      serviceTime = "",
    } = locationInfo;

    setFieldValue(`orderItems.${activeItemTab}.recipientInfo`, {
      ...createOrderValues?.orderItems[activeItemTab]?.recipientInfo,
      firstName,
      lastName,
      ...address,
      suite: address.addressLine2 || "",
      locationType,
      locationName,
    });
    setFieldValue(
      `orderItems.${activeItemTab}.deliveryInfo.deliveryDate`,
      deliveryDate
    );
    setFieldValue(
      `orderItems.${activeItemTab}.deliveryInfo.orderDeliveryTime`,
      deliveryTime
    );

    const visitationDateText = visitationDate
      ? `Visitation: ${moment(visitationDate).format("MM/DD/YYYY")}`
      : ``;
    const visitationTimeText = visitationTime
      ? `${moment(visitationTime).format("hh:mm A")}`
      : ``;

    const serviceDateText = serviceDate
      ? `Service: ${moment(serviceDate).format("MM/DD/YYYY")}`
      : ``;
    const serviceTimeText = serviceTime
      ? `${moment(serviceTime).format("hh:mm A")}`
      : ``;

    const visitationInfo = `${visitationDateText} ${visitationTimeText}`;
    const serviceInfo = `${serviceDateText} ${serviceTimeText}`;
    let di = [];
    //if (deliveryTimeText) di.push(deliveryTimeText);
    if (visitationInfo.trim()) di.push(visitationInfo);
    if (serviceInfo.trim()) di.push(serviceInfo);

    setFieldValue(
      `orderItems.${activeItemTab}.deliveryInfo.deliveryInstructions`,
      `${di.join(", ")}`
    );
    setFieldValue(
      `orderItems.${activeItemTab}.eventName`,
      `${firstName} ${lastName} ${deliveryDate}`
    );
    const funeralInfo = funeralEvents?.find(
      (x) => x.name === data.name && x.memberCode === data.memberCode
    );
    dispatch(setSelectedFuneralLog(funeralInfo));
    onComplete && onComplete();
  };

  const handlePrint = async () => {
    if (loading) return;
    setLoading(true);

    let html = "";
    const funeralOrders =
      Object.entries(customer?.details?.orderIds || {}) || [];

    try {
      await Promise.all(
        funeralOrders.map(async (entry) => {
          if (createOrderValues.sendingMember) {
            if (entry[0] !== createOrderValues.sendingMember) return;
          }
          const idsList = entry[1]?.filter((orderId) => !!orderId);
          if (!idsList || !idsList.length) return;

          const ordersRequests = getOrderDetailsRequests(idsList, entry[0]);
          const ordersList = await Promise.all(ordersRequests);
          const customerRequests = getCustomerDetailsRequests(ordersList);
          const floristAddress =
            UserProfileStorage.getShopLocation(entry[0]) || "";
          const businessName = UserProfileStorage.getShopName(entry[0]);
          const floristPhone = UserProfileStorage.getShopPhoneNumber(entry[0]);
          if (customerRequests.length > 0) {
            const customersList = await Promise.all(customerRequests);

            html =
              html +
              (await getPrintHTML({
                Localise,
                messages,
                celebrant: data?.celebrant,
                locationInfo: data?.locations[0],
                shopInfo: { floristAddress, businessName, floristPhone },
                ordersList,
                customersList,
              }));
          } else {
            const shopInfoHtml = await getPrintHTML({
              Localise,
              messages,
              celebrant: data?.celebrant,
              locationInfo: data?.locations[0],
              shopInfo: { floristAddress, businessName, floristPhone },
              ordersList,
            });
            html = html + shopInfoHtml;
          }
        })
      );
      setLoading(false);
      html && PrintIframeAction(html);
    } catch (error) {
      setLoading(false);
      console.log("Failed to print - ", error);
    }
  };

  const navigateToOrdersPage = () => {
    navigation.dispatch(
      CommonActions.navigate({
        name: "orders",
        params: {
          filters: [
            {
              section: "Date Range",
              title: "Custom Range",
              value: {
                startDate: moment(deliveryDate).format("YYYY-MM-DD"),
                endDate: moment(deliveryDate).format("YYYY-MM-DD"),
                dateType: "DELIVERY_DATE",
              },
            },
          ],
          orderIds: orderIdsList?.join(),
        },
      })
    );
  };

  return (
    <Accordion
      defaultOpen={defaultOpen}
      labelStyle={{
        backgroundColor: backgroundColors.secondary,
        borderBottomWidth: 0,
        minHeight: 20,
      }}
      headerContent={(openAccordion) => (
        <View
          style={[
            tw("flex flex-row items-center w-full"),
            { marginBottom: 5, minHeight: 26 },
          ]}
        >
          <View
            style={[
              tw(
                `flex flex-${isSmallScreen ? "column" : "row"} ${
                  isSmallScreen ? "" : "items-center"
                }`
              ),
              { width: isSmallScreen ? "70%" : "80%" },
            ]}
          >
            <View
              style={{
                width: "75%",
                marginRight: 10,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Text>
                {title}
                {ordersCount > 0 && (
                  <React.Fragment>
                    <Text>{` - `}</Text>
                    <TouchableComponent
                      onPress={() => {
                        if (formRef?.current?.dirty) {
                          setShowConfirmModal(true);
                        } else {
                          navigateToOrdersPage();
                        }
                      }}
                      testID={"ordersLink"}
                      accessibilityLabel={"ordersLink"}
                    >
                      <Text style={fonts.link1}>
                        {`${ordersCount} ${Localise(
                          messages,
                          `Order${ordersCount !== 1 ? `s` : ``}`
                        )}`}
                      </Text>
                    </TouchableComponent>
                  </React.Fragment>
                )}
              </Text>
            </View>
            {eventId && (
              <View
                style={{
                  width: isSmallScreen ? "50%" : "25%",
                  marginRight: 5,
                  marginTop: isSmallScreen ? 5 : 0,
                }}
              >
                <TouchableComponent
                  onPress={() => {
                    handleAddToOrder(
                      firstName,
                      lastName,
                      inputValues?.locations[0],
                      onComplete
                    );
                  }}
                  testID={"Add_to_Order"}
                  accessibilityLabel={"Add_to_Order"}
                >
                  <Text style={[fonts.link1]}>
                    {Localise(messages, "Add to Order")}
                  </Text>
                </TouchableComponent>
              </View>
            )}
          </View>
          <View
            style={[tw("flex flex-row items-center"), { marginLeft: "auto" }]}
          >
            {orderIds?.length > 0 && (
              <View style={[tw("flex flex-row"), { marginRight: 10 }]}>
                {loading && (
                  <ActivityIndicator
                    color={colors.activityIndicator}
                    style={{ marginRight: 10 }}
                  />
                )}
                <TouchableComponent
                  activeOpacity={0.5}
                  onPress={handlePrint}
                  testID="print"
                  accessibilityLabel="print"
                >
                  <Image
                    style={{
                      width: 26,
                      height: 26,
                    }}
                    source={IMAGES["print"]}
                  />
                </TouchableComponent>
              </View>
            )}
            <Image
              style={{
                width: 20,
                height: 20,
              }}
              source={
                IMAGES[openAccordion ? "expanded-section" : "collapsed-section"]
              }
            />
          </View>
        </View>
      )}
      handleOnPress={() => {}}
      containerStyle={{
        borderWidth: 1,
        borderColor: colors.light,
        marginTop: 10,
      }}
      contentStyle={{
        backgroundColor: "white",
        borderWidth: 0,
        paddingHorizontal: 5,
      }}
    >
      <Form
        initialValues={inputValues}
        onSubmit={(values, formikBag) => {
          const {
            celebrant: { firstName, lastName },
            celebrant,
            _id,
            __v,
            createdOn,
            updatedOn,
            startAndEndDate,
            startTime,
            ...other
          } = values;

          const {
            deliveryDate,
            deliveryTime = "",
            visitationDate = "",
            visitationTime = "",
            serviceDate = "",
            serviceTime = "",
          } = values.locations[0];

          const isCreate = !values?.eventId;
          const deliveryDateFormatted =
            moment(deliveryDate).format("YYYY-MM-DD");
          const name = `${firstName} ${lastName} ${deliveryDate}`;

          // Convert Funeral log info to Event location info
          const locations = [
            {
              ...other.locations[0],
              name: other?.locations[0]?.locationName,
              title: other?.locations[0]?.locationType,
              deliveryDate: deliveryTime
                ? moment(
                    `${deliveryDateFormatted}${`T${
                      deliveryTime?.split("T")[1]
                    }`}`
                  )
                    .utc()
                    .format()
                : deliveryDateFormatted,
              locationTimes: [
                {
                  startTime: visitationDate
                    ? visitationTime
                      ? moment(
                          `${visitationDate}${`T${
                            visitationTime?.split("T")[1]
                          }`}`
                        )
                          .utc()
                          .format()
                      : visitationDate
                    : "",
                  label: "visitation",
                },
                {
                  startTime: serviceDate
                    ? serviceTime
                      ? moment(
                          `${serviceDate}${`T${serviceTime?.split("T")[1]}`}`
                        )
                          .utc()
                          .format()
                      : serviceDate
                    : "",
                  label: "service",
                },
              ],
            },
          ];

          let memberCodes = UserProfileStorage.getProfileMemberCodes() || [];
          memberCodes = memberCodes.concat(
            UserProfileStorage.getGroupMemberCodes() || []
          );

          Promise.all(
            uniq(memberCodes).map((memberCode) => {
              if (!isCreate) {
                const previousFuneralInfo = funeralEvents?.find(
                  (x) => x.name === data.name && x.memberCode === memberCode
                );
                if (!previousFuneralInfo) return;
                const {
                  _id,
                  __v,
                  createdOn,
                  updatedOn,
                  startAndEndDate,
                  startTime,
                  ...otherInfo
                } = previousFuneralInfo;
                return request("update-event", {
                  reqObj: {
                    ...otherInfo,
                    memberCode,
                    locations,
                    celebrant,
                    name,
                    proposal: {
                      ...values.proposal,
                      status: "DRAFT",
                    },
                  },
                });
              } else {
                return request("save-event", {
                  reqObj: {
                    ...other,
                    memberCode,
                    locations,
                    celebrant,
                    name,
                    proposal: {
                      ...values.proposal,
                      status: "DRAFT",
                    },
                  },
                });
              }
            })
          )
            .then((response) => {
              isCreate
                ? handleAddToOrder(
                    firstName,
                    lastName,
                    values?.locations[0],
                    onComplete
                  )
                : onComplete && onComplete();
              ToasterHandler(
                "nice",
                Localise(
                  messages,
                  `Your Funeral Log ${
                    isCreate ? "created" : "updated"
                  } successfully`
                )
              );
              dispatch(
                setPageAction({
                  type: "create",
                  value: undefined,
                })
              );
              dispatch(fetchAllEvents({ eventType: "FUNERAL_LOG" }));
            })
            .catch((error) => {
              console.log("error: " + JSON.stringify(error));
              let errorMsg = `Failed to ${
                isCreate ? "create" : "update"
              } Funeral Log.`;
              if (error.includes("already exists")) {
                errorMsg += " Funeral Log already exists!";
              }
              ToasterHandler("uh oh", Localise(messages, errorMsg));
            })
            .finally(() => {
              formikBag.setSubmitting(false);
            });
        }}
        validationSchema={createFuneralLogValidationSchema(messages, Localise)}
        validateOnChange={validationOnChange}
        validateOnBlur={validationOnChange}
        render={({
          submitCount,
          values,
          setValues,
          touched,
          setFieldValue,
        }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            if (submitCount > 0) {
              setValidationOnChange(true);
            }
          }, [submitCount]);

          const { minTime, maxTime } = getDeliveryTimeMinAndMaxTime({
            deliveryDate: get(values, `${formIKPath}.deliveryDate`),
          });

          return (
            <View
              key={index}
              style={[
                tw(
                  `flex flex-row flex-wrap justify-start pb-2 items-${
                    Platform.OS !== "web" ? "center" : "baseline"
                  }`
                ),
                { marginLeft: 5, marginTop: 10 },
              ]}
            >
              {fields.map((fieldInfo, index) => {
                const { formFieldElement: Component, formFieldProps } =
                  fieldInfo;

                return (
                  <View
                    style={{
                      width: isSmallScreen ? "100%" : formFieldProps?.width,
                    }}
                    key={index}
                  >
                    <Component {...formFieldProps} />
                  </View>
                );
              })}
              <LocationInfo
                locationType={values.locations[0]?.locationType || ""}
                address={values.locations[0]?.address || ""}
                path={formIKPath}
                onSelect={(selectedValue, address) => {
                  setFieldValue(
                    `${formIKPath}.locationName`,
                    selectedValue?.terms[0]?.value
                  );
                  const formAddress = get(values, `${formIKPath}.address`, {});
                  setFieldValue(`${formIKPath}.address`, {
                    ...formAddress,
                    ...address,
                  });
                }}
                isSmallScreen={isSmallScreen}
                zIndex={1}
              />
              <View style={{ width: "100%", zIndex: 2 }}>
                <Address
                  values={values}
                  setValues={setValues}
                  isSmallScreen={isSmallScreen}
                  formIKPath={`${formIKPath}.address`}
                  ignoreAVS={values?.eventId && isEmpty(touched)}
                  index={0}
                  zipLabel={"Zip"}
                  popperPlacement={"top"}
                  suiteName="addressLine2"
                />
              </View>
              {additionalFields.map((fieldInfo, index) => {
                const { formFieldElement: Component, formFieldProps } =
                  fieldInfo;

                const timeProps =
                  formFieldProps.name === "deliveryTime"
                    ? { minTime, maxTime }
                    : undefined;

                const finalProps = { ...formFieldProps, ...timeProps };

                return (
                  <View
                    key={index}
                    style={{
                      width: isSmallScreen ? "100%" : formFieldProps?.width,
                      zIndex: 100 + index * 2,
                    }}
                  >
                    <Component {...finalProps} />
                  </View>
                );
              })}
              <View
                style={{
                  display: "flex",
                  marginTop: 10,
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <SaveCancelButtons
                  buttonTitle={"Save"}
                  onCancel={() => {
                    !values.eventId &&
                      dispatch(
                        setPageAction({ type: "create", value: undefined })
                      );
                  }}
                  showWarningOnSave={orderIds?.length > 0}
                  warningText={`Updating funeral details won't update the existing orders. Do you want to proceed?`}
                />
              </View>
            </View>
          );
        }}
        innerRef={formRef}
      />
      <ConfirmModal
        modalVisible={showConfirmModal}
        handlePrimary={() => {
          setShowConfirmModal(false);
        }}
        contentStyle={{ maxWidth: 340 }}
        handleSecondary={() => {
          setShowConfirmModal(false);
          navigateToOrdersPage();
        }}
        data={{
          modal: {
            primary: "Continue",
            secondary: "Nevermind",
            content: Localise(
              messages,
              `Do you want to Save your changes before viewing the orders?`
            ),
          },
        }}
      />
    </Accordion>
  );
};

export default FuneralListItem;
