import React, { useEffect } from "react";
import {
  ActivityIndicator,
  View,
  TouchableOpacity,
  Platform,
  Image,
} from "react-native";
import { Text } from "react-native-elements";
import { useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";

import tw from "tailwind-rn";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

import {
  FormField,
  FormFieldPicker,
  FormFieldAutoComplete,
  FormFieldSwitch,
} from "components/elements/forms";

import { Tooltip } from "components/elements";
import { fonts, colors, shapes } from "styles/theme";

import I18NContext from "library/contexts/i18N";
import { AppSettingsContext } from "library/contexts/appSettings";
import IMAGES from "static/assets/images";

import UserProfileStorage from "library/storage/userProfile";

import { request } from "library/utils/request";
import useStateIfMounted from "library/utils/useStateIfMounted";
import { formatPrice } from "library/utils/formatter";
import { Entitlements } from "library/utils/entitlements";
import Environment from "library/utils/environment";

import { selectApiResponse } from "library/sagas/ongoing/order-details/selector";
import { fetchCustomerOrders } from "library/sagas/views/home/drawer/create-order/slice";
import { selectCustomerOrders } from "library/sagas/views/home/drawer/create-order/selector";

import { getCurrentPOSSettings } from "components/views/drawer/shop-settings/helper";

import { basicPaymentInfo, basicCustomerInfo } from "../config";
import { isEmptyCustomerInfo } from "./helper";
import { getBillingInfo } from "../helper";
import { processCustomerDetailsResponse } from "library/sagas/views/home/drawer/customer-directory/upsert-screen";
import { setPageData } from "library/sagas/views/home/drawer/customer-directory/slice";
import CustomerVerificationModal from "components/views/drawer/create-order/customer-verification-modal";

import {
  phoneNumberFormatter,
  formatPhoneForPayload,
} from "library/utils/formatter";

let customerSuggestionsController;

//eslint-disable-next-line
const CustomerProfileInfo = React.memo(
  ({
    isSmallScreen,
    index,
    title,
    values,
    sendingMemberCode,
    canModifyOrder = true,
    setSideCarOpen,
    isEventPlanner = false,
    errors,
    isActionDraft,
  }) => {
    const { setFieldValue, initialValues } = useFormikContext();
    const dispatch = useDispatch();
    const customerOrders = useSelector(selectCustomerOrders) || [];

    const { messages, Localise } = React.useContext(I18NContext);
    const { permissions = {} } = React.useContext(AppSettingsContext);

    const [customerSuggestions, setCustomerSuggestions] = useStateIfMounted([]);
    const [existingcustomers, setExistingcustomers] = useStateIfMounted([]);
    const [showCustomerVerifyModal, setShowCustomerVerifyModal] =
      useStateIfMounted(false);

    const [loading, setLoading] = useStateIfMounted(false);
    const orderDetailsResponse = useSelector(selectApiResponse);
    const isSMSEligible =
      !isEventPlanner && Environment.get("IS_SMS_ENABLED", true);
    const showCustomerSuggestions = Environment.get(
      "SHOW_CUSTOMER_SUGGESTIONS",
      true
    );

    const {
      customerInfo: {
        customerType = "Individual",
        customerId = "",
        initialCustomerId = "",
        storeOrigin = "",
        customerContacts = [],
        selectContact = "",
        email = "",
        phone = "",
      } = {},
      orderItems = [],
      paymentDetails = {},
      isEditOrder = false,
      isCopyOrder = false,
    } = values;

    const orderDirection = get(
      orderDetailsResponse,
      "orderItems.0.direction",
      ""
    );

    const originalSendingMember = get(
      orderDetailsResponse,
      "orderItems.0.sendingMember.memberCode",
      ""
    );

    // Showing Edit for OutGoing Wired Orders so, In case of edit order if orderDirection is OUTBOUND then considering it as WireOrder
    const selfOutGoingOrder =
      isEditOrder &&
      originalSendingMember === sendingMemberCode &&
      orderDirection === "OUTBOUND";

    const path = "customerInfo";

    const selectedShopPermissions = get(permissions, sendingMemberCode, {});

    const isCPSEntitlementEnabled = selectedShopPermissions[
      Entitlements.CREATE_ORDER
    ]?.includes(Entitlements.CREATE_ORDER_PAGE.CUSTOMER_SEARCH);

    const { paymentMethodType } =
      (paymentDetails?.paymentMethod && paymentDetails?.paymentMethod[0]) || "";

    const hasCustomerInsights = !isEditOrder && customerOrders.length > 0;

    const isElectronApp =
      Platform.OS === "web" &&
      document.getElementById("isElectronApp").value === "true";

    const getCustomerSuggestions = (text) => {
      if (text.trim().length < 2) {
        setCustomerSuggestions([]);
        return;
      }

      customerSuggestionsController && customerSuggestionsController.abort();
      customerSuggestionsController = new AbortController();

      setLoading(true);
      request(
        "get-customers",
        { searchText: text, offset: 0, limit: 10, includeTotalCount: false },
        customerSuggestionsController
      )
        .then((res) => {
          if (res?.content?.length) {
            let response = res.content.map((data) => ({
              ...data,
              ...(data?.phones?.length
                ? { phones: phoneNumberFormatter(data?.phones[0]) }
                : {}),
            }));
            setCustomerSuggestions(response);
          } else setCustomerSuggestions([]);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const getCustomerInfo = (search, searchBy) => {
      request("get-customers-info", { search, searchBy })
        .then((res) => {
          setExistingcustomers(res);
          setShowCustomerVerifyModal(true);
        })
        .catch((err) => {
          console.log("err ==>", err);
        });
    };

    const setCustomerInfoFields = (selectedValue) => {
      const {
        customerId = "",
        storeOrigin = "",
        firstName = "",
        lastName = "",
        phones = [],
        email = "",
        customerContacts = [],
        isBusinessProfile = false,
      } = selectedValue;

      setFieldValue("customerInfo", {
        customerType: isBusinessProfile ? "Business" : "Individual",
        customerId,
        storeOrigin,
        addresses: [],
        firstName,
        lastName,
        email,
        phone: isArray(phones) ? phoneNumberFormatter(phones[0] || "") : phones,
        customerContacts,
      });
    };

    const getDiscountType = (
      orderItemIndex,
      priceIndex,
      customerResponse = {}
    ) => {
      let discountType = null;
      if (initialCustomerId !== customerResponse?.customerId) {
        discountType = customerResponse.discountPercentage
          ? "Percentage"
          : null;
      } else {
        const price = !isEventPlanner
          ? get(orderItems, `${orderItemIndex}.price`, [])
          : [];
        discountType = price[priceIndex]?.initialDiscountType || null;
      }
      return discountType;
    };

    const getDiscountValue = (
      orderItemIndex,
      priceIndex,
      customerResponse = {}
    ) => {
      let discountValue = 0;
      if (initialCustomerId !== customerResponse?.customerId) {
        discountValue = customerResponse.discountPercentage
          ? formatPrice(customerResponse.discountPercentage)
          : 0;
      } else {
        const price = !isEventPlanner
          ? get(orderItems, `${orderItemIndex}.price`, [])
          : [];
        discountValue = price[priceIndex]?.initialDiscount || 0;
      }
      return discountValue;
    };

    const getCustomerDetails = (customerId, storeOrigin) => {
      setLoading(true);
      request("get-customer-details", {
        customerId,
        storeOrigin,
      })
        .then(async (res) => {
          if (res) {
            //Update the redux so that payment we can use the customer info anywhere
            dispatch(
              setPageData({
                customerDetails: processCustomerDetailsResponse({
                  response: res,
                  filterAddresses: false,
                }),
              })
            );

            let updatedCustomerDetails = cloneDeep(res);

            // As we are not using paymentInfo of customer so instead of saving complete response, removing paymentInfo
            // before saving them in Formik and also to stop sending them in create/edit order request payload.
            delete updatedCustomerDetails.paymentInfo;

            setFieldValue("actualCustomerInfo", updatedCustomerDetails);

            const firstPaymentInfo =
              (res.paymentInfo || []).find(
                (info) => info.tokenId && info.tokenId !== "string"
              ) || {};

            const initialCustomerInfo = get(initialValues, "customerInfo", {});

            const customerInfo = {
              ...updatedCustomerDetails,
              initialCustomerId,
              phone: phoneNumberFormatter(get(res, "phones.0", "")),
              customerType: res.isBusinessProfile ? "Business" : "Individual",
              customerNotes: res.customerNotes || "",
              firstPaymentInfo,
              savedPaymentInfo:
                (res.paymentInfo || []).filter(
                  (info) => info.tokenId && info.tokenId !== "string"
                ) || {},
              ...(isActionDraft && // Ensure that updated customer details are prefilled when reopening a draft.
                initialCustomerId && {
                  firstName: initialCustomerInfo.firstName,
                  lastName: initialCustomerInfo.lastName,
                  phone: initialCustomerInfo.phone,
                  email: initialCustomerInfo.email,
                  customerType: initialCustomerInfo.customerType,
                  businessName: initialCustomerInfo.businessName,
                  customerNotes: initialCustomerInfo.customerNotes,
                  smsOptIn: initialCustomerInfo.smsOptIn,
                  smsProviderOptIn: initialCustomerInfo.smsProviderOptIn,
                }),
            };

            if (customerInfo.houseAccountInfo) {
              customerInfo.houseAccountInfo.orderTotal = get(
                values,
                "customerInfo.houseAccountInfo.orderTotal",
                0
              );
            }

            const billingAddress = get(res, "addresses.0", {});
            let updatedBillingInfo = {};

            if (!isEmpty(billingAddress)) {
              updatedBillingInfo = getBillingInfo(billingAddress);
            }

            customerInfo.billingInformation = updatedBillingInfo;

            //To pre-populate the selected contact (if any) while modifying/copying the order, as well as while pre-populating a draft order.
            if (
              (isEditOrder || isCopyOrder || isActionDraft) &&
              customerId === initialValues?.customerInfo?.customerId
            ) {
              customerInfo.selectContact =
                initialValues.customerInfo?.selectContact || "";
            }
            setFieldValue("customerInfo", customerInfo);

            if (!isEmpty(values?.customerInfo?.selectContact)) {
              const selectContact = JSON.parse(
                values?.customerInfo?.selectContact
              );
              if (selectContact.isPartial) {
                setFieldValue(
                  "customerInfo.selectContact",
                  JSON.stringify({
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",
                  })
                );
                setFieldValue("customerInfo.selectedContact", selectContact);
              }
            }

            // To prefill billing info for Payment Terminal in case of Desktop app
            let prefillBillingInfo = false;
            if (isElectronApp) {
              const { terminal_settings = [] } =
                UserProfileStorage.getShopPreferences(sendingMemberCode);

              const { currentPOSSettings = {} } = await getCurrentPOSSettings(
                terminal_settings
              );

              if (
                currentPOSSettings?.serial_number &&
                currentPOSSettings?.ip_address
              ) {
                prefillBillingInfo = true;
              }
            }

            if (
              ["SAVED_CARD", "CREDIT_CARD", "PAYMENT_TERMINAL"].includes(
                paymentMethodType
              ) ||
              prefillBillingInfo
            ) {
              let paymentMethodDetails;

              if (
                paymentMethodType === "SAVED_CARD" &&
                !isEmpty(firstPaymentInfo)
              ) {
                const {
                  lastFourDigits,
                  expirationMonth,
                  expirationYear = "",
                  nameOnCard,
                  billingAddress = {},
                } = firstPaymentInfo;

                paymentMethodDetails = {
                  name: nameOnCard,
                  cardNumber: "***" + lastFourDigits,
                  cvv: "",
                  expDate: expirationMonth + "/" + expirationYear.substring(2),
                };

                if (!isEmpty(billingAddress)) {
                  updatedBillingInfo = getBillingInfo(billingAddress);
                }
              } else {
                paymentMethodDetails =
                  values?.paymentDetails?.paymentMethod[0]
                    ?.paymentMethodDetails ??
                  basicPaymentInfo.paymentMethod[0].paymentMethodDetails;

                if (isEmpty(updatedBillingInfo)) {
                  updatedBillingInfo =
                    values?.paymentDetails?.paymentMethod[0]
                      ?.billingInformation ??
                    basicPaymentInfo.paymentMethod[0].billingInformation;
                }
              }

              setFieldValue(
                "paymentDetails.paymentMethod.0.billingInformation",
                updatedBillingInfo
              );

              setFieldValue(
                "paymentDetails.paymentMethod.0.paymentMethodDetails",
                paymentMethodDetails
              );
            }

            const orderItemsCopy = get(values, "orderItems");
            if (orderItemsCopy && orderItemsCopy.length) {
              orderItemsCopy.map((item, index) => {
                if (res.taxExemptCode) {
                  setFieldValue(`orderItems.${index}.taxInfo`, {
                    taxAmount: 0,
                    taxRate: 0.0,
                  });
                }

                item.price.map((_priceVal, idx) => {
                  setFieldValue(
                    `orderItems.${index}.price.${idx}.discountType`,
                    getDiscountType(index, idx, res)
                  );

                  setTimeout(() => {
                    setFieldValue(
                      `orderItems.${index}.price.${idx}.discount`,
                      getDiscountValue(index, idx, res)
                    );
                  }, 0);
                });
              });
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    useEffect(() => {
      if (customerId && storeOrigin) {
        getCustomerDetails(customerId, storeOrigin);
      }
      !isEventPlanner &&
        dispatch(fetchCustomerOrders({ storeOrigin, customerId }));
    }, [customerId]);

    useEffect(() => {
      if (setSideCarOpen)
        hasCustomerInsights
          ? setSideCarOpen("customer_insights")
          : !isSmallScreen && setSideCarOpen("additional_info");
    }, [customerOrders.length]);

    useEffect(() => {
      const {
        isBusinessProfile,
        customerType = "",
        businessName = "",
        firstName = "",
        lastName = "",
      } = values.customerInfo || {};

      const { customerInfo, ...other } = values;

      other?.orderItems?.forEach((_each, index) => {
        const deliveryInfo = get(other, `orderItems.${index}.deliveryInfo`);

        if (
          deliveryInfo?.deliveryMethod === "STORE_PICKUP" &&
          !deliveryInfo.pickUpBy
        ) {
          const isBusinessAccount =
            isBusinessProfile || customerType === "Business";
          if (isBusinessAccount ? businessName : firstName && lastName) {
            const name = `${firstName.trim()} ${lastName.trim()}`;
            setFieldValue(`orderItems.${index}.deliveryInfo`, {
              ...deliveryInfo,
              pickUpBy: isBusinessAccount ? businessName.trim() : name.trim(),
            });
          }
        }
      });

      if (values.hasCustomerInfo && isEmptyCustomerInfo(values.customerInfo)) {
        // if user clears customerInfo UI fields then resetting customer fields
        setFieldValue("customerInfo", basicCustomerInfo);
        setFieldValue("hasCustomerInfo", false);
        setFieldValue(`paymentDetails.paymentMethod.0.paymentMethodType`, "");
        delete values.actualCustomerInfo;
      } else {
        !isEventPlanner &&
          setFieldValue(
            "hasCustomerInfo",
            Object.values(values.customerInfo).filter(
              (val) => !!val && val.length > 0
            ).length > 0
          );
      }
    }, [values.customerInfo]);

    return isCPSEntitlementEnabled || isEventPlanner ? (
      <View
        style={[
          {
            ...shapes.sectionBorder,
            marginTop: 15,
            opacity: canModifyOrder || selfOutGoingOrder ? 1 : 0.7,
            width: "100%",
          },
        ]}
        pointerEvents={canModifyOrder || selfOutGoingOrder ? "auto" : "none"}
      >
        <View style={[tw("flex flex-row pb-3"), { paddingHorizontal: 5 }]}>
          <Text style={[fonts.sectionHeading]}>
            {Localise(messages, title)}
          </Text>

          {loading && (
            <ActivityIndicator
              style={{ marginLeft: 5, marginTop: -5 }}
              color={colors.activityIndicator}
            />
          )}
        </View>

        <View style={[tw("flex flex-row flex-wrap")]}>
          <FormFieldAutoComplete
            autoCapitalize="none"
            autoCorrect={false}
            name="name"
            placeholder={Localise(messages, "Search customer records")}
            path={path}
            data={customerSuggestions}
            onChangeText={(text) => {
              getCustomerSuggestions(text);
            }}
            outerContainerStyle={{
              width: "100%",
              zIndex: 3,
              marginBottom: 4,
            }}
            listDisplayValues={[
              "businessName",
              "firstName",
              "lastName",
              "email",
              "phones",
            ]}
            onSelect={(selectedValue, setValue) => {
              setValue("");
              setCustomerInfoFields(selectedValue);
            }}
            popperPlacement={"bottom"}
          />
          {!isEventPlanner && (
            <Text
              style={[
                fonts.sectionHeading,
                { fontSize: 13, paddingHorizontal: 5 },
                tw("w-full pb-3"),
              ]}
            >
              {Localise(messages, "Customer Profile")}
            </Text>
          )}
          <FormFieldPicker
            placeholder={{ label: "Select Customer Type" }}
            containerStyle={{
              width: isSmallScreen ? "100%" : "40%",
            }}
            data={[
              { label: "Individual", value: "Individual" },
              { label: "Business", value: "Business" },
            ]}
            name="customerType"
            label={Localise(messages, "Customer Type")}
            labelStyle={{ fontWeight: "normal" }}
            path={path}
            testID="customer_type"
            accessibilityLabel="customer_type"
            disabledFieldTouch={true}
          />

          {customerType !== "Business" ? (
            <>
              {showCustomerSuggestions ? (
                <>
                  <FormFieldAutoComplete
                    autoCapitalize="none"
                    autoCorrect={false}
                    name="firstName"
                    placeholder={Localise(messages, "First Name")}
                    label={Localise(messages, "First Name")}
                    labelStyle={{ fontWeight: "normal" }}
                    path={path}
                    data={customerSuggestions}
                    onChangeText={(text) => {
                      getCustomerSuggestions(text);
                      setFieldValue("customerInfo", {
                        ...values?.customerInfo,
                        firstName: text,
                      });
                    }}
                    outerContainerStyle={{
                      width: isSmallScreen ? "100%" : "30%",
                      zIndex: 2,
                      marginBottom: 4,
                    }}
                    listDisplayValues={["firstName", "lastName", "email"]}
                    onSelect={(selectedValue) => {
                      setCustomerInfoFields(selectedValue);
                    }}
                    popperPlacement={"bottom"}
                    testID="customer_firstName"
                    accessibilityLabel="customer_firstName"
                    showErrorOnTouched={true}
                  />
                  <FormFieldAutoComplete
                    autoCapitalize="none"
                    autoCorrect={false}
                    name="lastName"
                    placeholder={Localise(messages, "Last Name")}
                    label={Localise(messages, "Last Name")}
                    labelStyle={{ fontWeight: "normal" }}
                    path={path}
                    data={customerSuggestions}
                    onChangeText={(text) => {
                      getCustomerSuggestions(text);
                      setFieldValue("customerInfo", {
                        ...values?.customerInfo,
                        lastName: text,
                      });
                    }}
                    outerContainerStyle={{
                      width: isSmallScreen ? "100%" : "30%",
                      zIndex: 1,
                      marginBottom: 4,
                    }}
                    listDisplayValues={["firstName", "lastName", "email"]}
                    onSelect={(selectedValue) => {
                      setCustomerInfoFields(selectedValue);
                    }}
                    popperPlacement={"bottom"}
                    testID="customer_lastName"
                    accessibilityLabel="customer_lastName"
                    showErrorOnTouched={true}
                  />
                </>
              ) : (
                <>
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="new-password" //hack for autoComplete off
                    name="firstName"
                    path={path}
                    containerStyle={{
                      width: isSmallScreen ? "100%" : "30%",
                    }}
                    placeholder={Localise(messages, "First Name")}
                    label={Localise(messages, "First Name")}
                    labelStyle={{ fontWeight: "normal" }}
                    testID="customer_firstName"
                    accessibilityLabel="customer_firstName"
                  />
                  <FormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    autoComplete="new-password" //hack for autoComplete off
                    name="lastName"
                    path={path}
                    containerStyle={{
                      width: isSmallScreen ? "100%" : "30%",
                    }}
                    placeholder={Localise(messages, "Last Name")}
                    label={Localise(messages, "Last Name")}
                    labelStyle={{ fontWeight: "normal" }}
                    testID="customer_lastName"
                    accessibilityLabel="customer_lastName"
                  />
                </>
              )}
            </>
          ) : showCustomerSuggestions ? (
            <FormFieldAutoComplete
              autoCapitalize="none"
              autoCorrect={false}
              name="businessName"
              placeholder={Localise(messages, "Business Name")}
              label={Localise(messages, "Business Name")}
              labelStyle={{ fontWeight: "normal" }}
              path={path}
              data={customerSuggestions}
              onChangeText={(text) => {
                getCustomerSuggestions(text);
                setFieldValue("customerInfo", {
                  ...values?.customerInfo,
                  businessName: text,
                });
              }}
              outerContainerStyle={{
                width: isSmallScreen ? "100%" : "60%",
                zIndex: 2,
                marginBottom: 4,
              }}
              listDisplayValues={["businessName", "phone", "email"]}
              onSelect={(selectedValue) => {
                setCustomerInfoFields(selectedValue);
              }}
              popperPlacement={"bottom"}
              testID="customer_businessName"
              accessibilityLabel="customer_businessName"
              showErrorOnTouched={true}
            />
          ) : (
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password" //hack for autoComplete off
              name="businessName"
              path={path}
              containerStyle={{
                width: isSmallScreen ? "100%" : "60%",
              }}
              placeholder={Localise(messages, "Business Name")}
              label={Localise(messages, "Business Name")}
              labelStyle={{ fontWeight: "normal" }}
              testID="customer_businessName"
              accessibilityLabel="customer_businessName"
            />
          )}
          {!isEventPlanner && (
            <>
              <Text
                style={[
                  fonts.sectionHeading,
                  { fontSize: 13, paddingHorizontal: 5 },
                  tw("w-full pb-3 pt-1"),
                ]}
              >
                {Localise(messages, "Customer Profile Contact")}
              </Text>
              <FormFieldPicker
                placeholder={{ label: "Default" }}
                containerStyle={{
                  width: isSmallScreen ? "100%" : "40%",
                }}
                data={[
                  ...customerContacts.map((contact) => {
                    const label = [
                      contact.firstName,
                      contact.lastName || "",
                      phoneNumberFormatter(contact.phone || ""),
                      contact.email || "",
                    ]
                      .filter(Boolean)
                      .join(" ");
                    return {
                      label: label,
                      value: JSON.stringify(contact),
                    };
                  }),
                  ...(customerId
                    ? [
                        {
                          label: "+ Add Contact",
                          value: JSON.stringify({
                            firstName: "",
                            lastName: "",
                            phone: "",
                            email: "",
                          }),
                        },
                      ]
                    : []),
                ]}
                name="selectContact"
                label={Localise(messages, "Select Contact")}
                labelStyle={{ fontWeight: "normal" }}
                onChange={(contact) => {
                  if (contact) {
                    const parsedContact = JSON.parse(contact);
                    const formattedPhone =
                      phoneNumberFormatter(parsedContact.phone) || "";
                    setFieldValue("customerInfo.selectedContact", {
                      ...parsedContact,
                      phone: formattedPhone,
                      isSelected: true,
                    });
                  } else {
                    setFieldValue("customerInfo.selectedContact", {});
                  }
                }}
                path={path}
                testID="select_customer_contact"
                accessibilityLabel="select_customer_contact"
                disabled={
                  errors?.customerInfo?.phone || errors?.customerInfo?.email
                }
              />
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="new-password" //hack for autoComplete off
                name={selectContact ? "selectedContact.firstName" : "firstName"}
                grayedOutOnDisable={!selectContact}
                editable={!!selectContact}
                path={path}
                containerStyle={{
                  width: isSmallScreen ? "100%" : "30%",
                }}
                placeholder={Localise(messages, "First Name")}
                label={Localise(messages, "First Name")}
                labelStyle={{ fontWeight: "normal" }}
                testID="customer_contact_firstName"
                accessibilityLabel="customer_contact_firstName"
              />
              <FormField
                autoCapitalize="none"
                autoCorrect={false}
                autoComplete="new-password" //hack for autoComplete off
                name={selectContact ? "selectedContact.lastName" : "lastName"}
                grayedOutOnDisable={!selectContact}
                editable={!!selectContact}
                path={path}
                containerStyle={{
                  width: isSmallScreen ? "100%" : "30%",
                }}
                placeholder={Localise(messages, "Last Name")}
                label={Localise(messages, "Last Name")}
                labelStyle={{ fontWeight: "normal" }}
                testID="customer_contact_lastName"
                accessibilityLabel="customer_contact_lastName"
              />
            </>
          )}
          {showCustomerSuggestions ? (
            <FormFieldAutoComplete
              autoCapitalize="none"
              autoCorrect={false}
              name={
                isEventPlanner || !selectContact
                  ? "phone"
                  : "selectedContact.phone"
              }
              placeholder={"123-456-7890"}
              maxLength={18}
              label={Localise(messages, "Phone")}
              labelStyle={{ fontWeight: "normal" }}
              path={path}
              data={isEventPlanner || !selectContact ? customerSuggestions : []}
              onChangeText={(text) => {
                if (isEventPlanner || !selectContact) {
                  getCustomerSuggestions(text);
                  setFieldValue("customerInfo", {
                    ...values?.customerInfo,
                    phone: phoneNumberFormatter(text),
                  });
                } else {
                  setFieldValue("customerInfo.selectedContact", {
                    ...values?.customerInfo?.selectedContact,
                    phone: phoneNumberFormatter(text),
                    isSelected: true,
                  });
                }
              }}
              outerContainerStyle={{
                width: isSmallScreen ? "100%" : isSMSEligible ? "30%" : "40%",
                zIndex: 1,
                marginBottom: 4,
              }}
              listDisplayValues={["phones"]}
              onSelect={(selectedValue) => {
                setCustomerInfoFields(selectedValue);
              }}
              popperPlacement={"bottom"}
              testID="customer_phone"
              accessibilityLabel="customer_phone"
              showErrorOnTouched={true}
              handleOnBlur={(val) => {
                email?.length === 0 &&
                  (isEventPlanner || !selectContact) &&
                  getCustomerInfo(formatPhoneForPayload(val), "phone");
              }}
            />
          ) : (
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password" //hack for autoComplete off
              name={
                isEventPlanner || !selectContact
                  ? "phone"
                  : "selectedContact.phone"
              }
              fsClass="fs-exclude"
              path={path}
              maxLength={18}
              containerStyle={{
                width: isSmallScreen ? "100%" : isSMSEligible ? "30%" : "40%",
              }}
              placeholder={"123-456-7890"}
              label={Localise(messages, "Phone")}
              labelStyle={{ fontWeight: "normal" }}
              transformText={(text = "") => {
                return phoneNumberFormatter(text);
              }}
              testID="customer_phone"
              accessibilityLabel="customer_phone"
            />
          )}
          {isSMSEligible && (
            <View
              style={[
                {
                  display: "flex",
                },
                isSmallScreen
                  ? {
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                      marginVertical: 10,
                      marginLeft: 5,
                    }
                  : {
                      flexDirection: "column",
                      alignItems: "center",
                      width: "10%",
                      marginVertical: 0,
                    },
              ]}
            >
              <Text
                style={
                  isSmallScreen
                    ? {
                        marginBottom: 0,
                        marginRight: 10,
                      }
                    : {
                        marginBottom: 13,
                        marginRight: 0,
                      }
                }
              >
                {Localise(messages, "SMS")}
              </Text>
              <FormFieldSwitch
                disabled={
                  !(values?.customerInfo?.customerId || values?.hasCustomerInfo)
                }
                value={
                  values?.customerInfo?.smsOptIn &&
                  values?.customerInfo?.smsProviderOptIn
                }
                onValueChange={(val) => {
                  if (val) {
                    setFieldValue(`${path}.smsOptIn`, val);
                    setFieldValue(`${path}.smsProviderOptIn`, val);
                  } else {
                    setFieldValue(`${path}.smsOptIn`, val);
                  }
                }}
                testID="smsOptIn"
              />
            </View>
          )}
          {showCustomerSuggestions ? (
            <FormFieldAutoComplete
              autoCapitalize="none"
              autoCorrect={false}
              name={
                isEventPlanner || !selectContact
                  ? "email"
                  : "selectedContact.email"
              }
              placeholder={Localise(messages, "Email")}
              label={Localise(messages, "Email")}
              labelStyle={{ fontWeight: "normal" }}
              path={path}
              data={isEventPlanner || !selectContact ? customerSuggestions : []}
              onChangeText={(text) => {
                if (isEventPlanner || !selectContact) {
                  getCustomerSuggestions(text);
                  setFieldValue("customerInfo", {
                    ...values?.customerInfo,
                    email: text,
                  });
                } else {
                  setFieldValue("customerInfo.selectedContact", {
                    ...values?.customerInfo?.selectedContact,
                    email: text,
                    isSelected: true,
                  });
                }
              }}
              outerContainerStyle={{
                width: isSmallScreen ? "85%" : isEventPlanner ? "60%" : "50%",
                marginBottom: 4,
              }}
              listDisplayValues={["email"]}
              onSelect={(selectedValue) => {
                setCustomerInfoFields(selectedValue);
              }}
              popperPlacement={"bottom"}
              testID="customer_email"
              accessibilityLabel="customer_email"
              showErrorOnTouched={true}
              handleOnBlur={(val) => {
                (isEventPlanner || !selectContact) &&
                  getCustomerInfo(
                    val === "" && phone?.length
                      ? formatPhoneForPayload(phone)
                      : val,
                    val === "" && phone?.length ? "phone" : "email"
                  );
              }}
            />
          ) : (
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password" //hack for autoComplete off
              name={
                isEventPlanner || !selectContact
                  ? "email"
                  : "selectedContact.email"
              }
              fsClass="fs-exclude"
              path={path}
              containerStyle={{
                width: isSmallScreen ? "85%" : isEventPlanner ? "60%" : "50%",
              }}
              placeholder={Localise(messages, "Email")}
              label={Localise(messages, "Email")}
              labelStyle={{ fontWeight: "normal" }}
              testID="customer_email"
              accessibilityLabel="customer_email"
            />
          )}
          {isEventPlanner && (
            <FormField
              autoCapitalize="none"
              autoCorrect={false}
              autoComplete="new-password" //hack for autoComplete off
              name="customerNotes"
              fsClass="fs-exclude"
              path={path}
              containerStyle={{
                width: "100%",
              }}
              placeholder={Localise(messages, "Notes")}
              label={Localise(messages, "Notes")}
              labelStyle={{ fontWeight: "normal" }}
            />
          )}
          {!isEventPlanner && (
            <TouchableOpacity
              style={[
                tw("flex flex-row justify-center items-center"),
                {
                  ...shapes.sectionBorder,
                  width: Platform.OS === "web" ? "calc(10% - 5px)" : "13%",
                  marginTop: 20,
                  borderColor: hasCustomerInsights
                    ? colors.primary
                    : colors.light,

                  height: 35,
                  padding: 0,
                },
              ]}
              disabled={!hasCustomerInsights}
              onPress={() => {
                setSideCarOpen("customer_insights");
              }}
              key={`customerInsightsIcon`}
              testID="customerInsightsIcon"
              accessibilityLabel="customerInsightsIcon"
            >
              <Tooltip
                text={Localise(messages, "Customer Insights")}
                renderForWebOnly={true}
              >
                <Image
                  style={{ width: 25, height: 25 }}
                  resizeMode="cover"
                  source={IMAGES["orders"]}
                />
              </Tooltip>
            </TouchableOpacity>
          )}
        </View>

        {showCustomerVerifyModal && showCustomerSuggestions && (
          <CustomerVerificationModal
            isSmallScreen={isSmallScreen}
            customerSuggestions={existingcustomers}
            showModal={showCustomerVerifyModal}
            onCancel={() => {
              setShowCustomerVerifyModal(false);
            }}
            onContinue={(val) => {
              setCustomerInfoFields(val);
              setShowCustomerVerifyModal(false);
            }}
          />
        )}
      </View>
    ) : null;
  }
);

export default CustomerProfileInfo;
